import type { KcmContent, AuthUser } from "@/types";
import type { RecurlySubscriptionData } from "@/types/recurly.types";
import type { ApplePaySubscriptionData } from "@/types";
import { getUAData, type DeviceData } from "./tracking.useragent";
import store from "@/state/store";
import GoogleAnalytics from "./GoogleAnalytics";
import router from "@/router";

interface BaseEvent {
  content_language: string;
  section: string;
  page_location: string;
  page_referrer: string;
  page_title: string;
}

interface SearchEvent {
  search_query: string;
}

interface FilterEvent extends SearchEvent {
  filter_page: number;
  filter_categories: string;
  filter_types: string;
  filter_published_since: string;
  filter_language: string;
}

interface DownloadEvent {
  file_extension: string;
  file_name: string;
}

interface SocialMediaEvent {
  social_platform: string;
}

interface GenericEventDataEvent {
  event_target: string;
}

type EventData =
  | DownloadEvent
  | SearchEvent
  | FilterEvent
  | SocialMediaEvent
  | GenericEventDataEvent
  | BaseEvent
  | undefined;

type PlatformData = {
  googleAnalytics?: {
    clientId: string;
    sessionId: string;
  };
};

// what is sent to the tracking lambda
interface TrackingPayload {
  event: string;
  user: AuthUser;
  subscription: RecurlySubscriptionData | ApplePaySubscriptionData;
  content: KcmContent;
  userAgent: DeviceData;
  eventData?: EventData & BaseEvent & { debug_mode?: boolean };
  platform?: PlatformData;
}

/**
 *
 * this is basically a low hanging patch until we can drop
 * the CZ tracker. we don't want to change any event names
 * being sent to CZ, and the event names we need to send to
 * the lambda tracker are sometimes different, so this allows
 * us to send the new event names without modifying names
 * in any other files.
 */
const eventNameMap: { [key: string]: string } = {
  // Content Events
  viewedContent: "view",
  sharedContent: "share",
  scheduledContent: "schedule",
  watchedContent: "watch",
  downloadedContent: "download",
  save: "save",
  filteredContent: "filter",
  searched: "search",
  playedAudio: "playAudio",
  upload: "upload",
  selectedContent: "selectContent",
  create: "create",
  customize: "customize",
  copiedContent: "copy",
  // Account Events
  login: "login",
  connectedSocial: "connectSocial",
  startTestDrive: "startTestDrive",
  // Subscription Events
  initiatePlanChange: "initiatePlanChange",
  startCancel: "startCancel",
  acceptCancelSave: "acceptCancelSave",
  acceptCancelDeflect: "acceptCancelDeflect",
  closeCancel: "closeCancel",
  declineCancelSave: "declineCancelSave",
};

function generateBasePayload(eventSlug: string, data: any): TrackingPayload {
  return {
    event: eventSlug,
    user: store.getters["auth/authUser"],
    subscription: store.getters["billing/billingSubscription"],
    content: data.content ?? {},
    userAgent: getUAData(),
    eventData: {} as EventData & BaseEvent,
    platform: {} as PlatformData,
  };
}

/**
 *
 * @param payload tracking payload
 * @param data the data coming from the trackEvent function which has a lot of different structures :(
 *
 * evaluates the payload event against the eventNameMap and prepares
 * any special event eventData with the needed format for the lambda tracker
 */
function prepareEventData(payload: TrackingPayload, data: any): EventData {
  switch (payload.event) {
    case eventNameMap.sharedContent:
      return ContentEventFormatters.prepareShareEvent(data);
    case eventNameMap.scheduledContent:
      return ContentEventFormatters.prepareShareEvent(data);
    case eventNameMap.downloadedContent:
      return ContentEventFormatters.prepareDownloadEvent(data);
    case eventNameMap.filteredContent:
      return ContentEventFormatters.prepareFilterEvent(data);
    case eventNameMap.searched:
      return ContentEventFormatters.prepareSearchEvent(data);
    case eventNameMap.copiedContent:
      return ContentEventFormatters.prepareCopyEvent(data);
    case eventNameMap.upload:
      return ContentEventFormatters.prepareUploadEvent(data);
    case eventNameMap.customize:
      return ContentEventFormatters.prepareCustomizeEvent(data);
    case eventNameMap.save:
      return ContentEventFormatters.prepareSaveEvent(data);
    case eventNameMap.create:
      return ContentEventFormatters.prepareCreateEvent(data);
    case eventNameMap.connectedSocial:
      return AccountEventFormatters.prepareConnectSocialEvent(data);
    case eventNameMap.acceptCancelSave:
      return SubscriptionEventFormatters.prepareCancelDeflectEvent(data);
    case eventNameMap.acceptCancelDeflect:
      return SubscriptionEventFormatters.prepareCancelSaveEvent(data);
    default:
      delete payload.eventData;
      return undefined;
  }
}

/**
 *
 * @param payload tracking payload
 * @param action the event action
 *
 * evaluates the event action against the event name map and modifies
 * the event string to the format needed for the lambda tracker
 */
function prepareEventSlug(payload: TrackingPayload, action: string): void {
  switch (payload.event) {
    case eventNameMap.watchedContent:
      payload.event = formatWatchedPercentage(action);
      break;
    default:
      break;
  }
}

/**
 *
 * @param watchedPercentage string ex. (50%)
 * @returns the event slug format required for lambda tracker: ex. watch50
 */
function formatWatchedPercentage(watchedPercentage: string): string {
  const regexp = /\(([^()]*)\)/g;
  // ex. [(50%)]
  const matches = watchedPercentage.match(regexp);
  if (!matches) return "";
  // extract percent value in between paranthesis
  const percent = matches.map((match) => match.slice(1, -1))[0];
  // ex. watch95
  return "watch" + percent.split("%")[0];
}

/**
 *
 * Group event formatters for content related events
 * that require unique metadata for the eventData property
 */
const ContentEventFormatters = {
  prepareShareEvent(data: any): SocialMediaEvent {
    return {
      social_platform: data.method,
    };
  },
  prepareDownloadEvent(data: any): DownloadEvent {
    const result = data.fileName?.match(/([^/.]+)$|([^/]+)(\.[^/.]+)$/);

    return {
      file_extension: result?.[3] ?? "",
      file_name: data.fileName ?? "download",
    };
  },

  prepareSearchEvent(data: any): SearchEvent {
    return {
      search_query: data.query ?? "",
    };
  },

  prepareCopyEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareUploadEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareFilterEvent(data: any): FilterEvent {
    return {
      filter_page: data.page,
      filter_categories: data.categories,
      filter_types: data.types,
      search_query: data.query ?? "",
      filter_published_since: data.published_since,
      filter_language: data.language ?? "",
    };
  },

  prepareCustomizeEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareSaveEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareCreateEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },
};

/**
 *
 * Group event formatters for account related events
 * that require unique metadata for the eventData property
 */
const AccountEventFormatters = {
  prepareConnectSocialEvent(data: any): SocialMediaEvent {
    return {
      social_platform: data.media,
    };
  },
};

/**
 *
 * Group event formatters for subscription related events
 * that require unique metadata for the eventData property
 */
const SubscriptionEventFormatters = {
  prepareCancelSaveEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },

  prepareCancelDeflectEvent(data: any): GenericEventDataEvent {
    return {
      event_target: data.eventTarget,
    };
  },
};

/**
 *
 * @param label the name of the event
 * @param data the <any> data passed in from TrackingService.trackEvent
 *
 * massages the data and the event names to the correct format before
 * sending the data off to the remote tracker
 */
export async function trackEvent(label: string, data: any): Promise<void> {
  const event = eventNameMap[label];

  if (!event) {
    return;
  }

  const payload = generateBasePayload(event, data);

  //try getting the client ID from GA so server side events
  //get attributed to the correct page view
  try {
    const gaClientId = await GoogleAnalytics.getClientId();
    const gaSessionId = await GoogleAnalytics.getSessionId();
    if (gaClientId !== "" && gaSessionId !== "") {
      payload.platform = {
        googleAnalytics: {
          clientId: gaClientId,
          sessionId: gaSessionId,
        },
      };
    }
  } catch (err) {
    console.error("Failed to get gaClientId: ", err);
  }

  /** Truncate large properties to stay within GA limits */
  if (payload.content) {
    payload.content.title = payload.content.title?.substring(0, 90);
    payload.content.contents = payload.content.contents?.substring(0, 90);
    payload.content.featured_image = payload.content.featured_image?.substring(
      0,
      90
    );
  }

  const eventSpecificData = prepareEventData(payload, data);
  const standardEventData: BaseEvent = {
    section: data.section,
    content_language: data.content_language,
    page_location: router.currentRoute.fullPath,
    page_referrer: document.referrer ?? "",
    page_title: document.title ?? "",
  };

  payload.eventData = {
    ...eventSpecificData,
    ...standardEventData,
  };

  if (data.action) {
    prepareEventSlug(payload, data.action);
  }

  sendEvent(payload);
}

function sendEvent(payload: TrackingPayload, verbosity = false): void {
  const action = "?action=track";
  const verbose = "&verbose=true";

  let baseUrl = process.env.VUE_APP_TRACKER_URL;

  if (!baseUrl) return;

  baseUrl += action;

  if (verbosity) {
    baseUrl += verbose;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  // just ignoring the response for now
  fetch(baseUrl, options);
}
